<template>
  <a-modal
    width="60%"
    wrap-class-name="receive-detail"
    :visible="visible"
    :mask-closable="false"
    :closable="false"
  >
    <template #title>
      <span>{{ $t('pages_receive_155') }}: {{ receiveInfo.applyNo }}</span>
      <span style="margin-left: 48px">{{ $t('pages_receive_156') }}: {{ receiveInfo.statusText }}</span>
    </template>
    <div class="receive-info">
      <div class="header-title">{{ $t('pages_receive_157') }}</div>
      <div class="info-body">
        <div class="item">{{ $t('pages_receive_067') }}: <span>{{ receiveInfo.payerName }}</span></div>
        <div class="item">{{ $t('pages_receive_158') }}: <span>{{ receiveInfo.payerBankNo }}</span></div>
        <div class="item">{{ $t('pages_receive_159') }}: <span>{{ receiveInfo.remark || '-' }}</span></div>
      </div>
      <div class="header-title">{{ $t('pages_receive_160') }}</div>
      <div class="info-body">
        <div class="item">{{ $t('pages_receive_013') }}: <span>{{ receiveInfo.transTypeText }}</span></div>
        <div class="item">{{ $t('pages_receive_021') }}: <span>{{ receiveInfo.shopName }}</span></div>
        <div class="item">{{ $t('pages_receive_003') }}: <span>{{ receiveInfo.receiveAccount }}</span></div>
        <div class="item flex-align">{{ $t('pages_receive_070') }}:
          <img :src="getCountryLogo(receiveInfo.currency)">
          <span style="margin-left: 0">{{ receiveInfo.receiveAmtText }}</span>
        </div>
        <div class="item flex-align">{{ $t('pages_receive_071') }}:
          <img :src="getCountryLogo(receiveInfo.feeCurrency)">
          <span style="margin-left: 0">{{ receiveInfo.feeAmtText }}</span>
        </div>
        <div class="item flex-align">{{ $t('pages_receive_072') }}:
          <img :src="getCountryLogo(receiveInfo.entryCurrency)">
          <span style="margin-left: 0">{{ receiveInfo.entryAmtText }}</span>
        </div>
        <div class="item">{{ $t('pages_receive_005') }}: <span>{{ receiveInfo.accountName }}</span></div>
        <div class="item">{{ $t('pages_receive_069') }}: <span>{{ receiveInfo.receiveTime }}</span></div>
      </div>
      <div v-if="receiveInfo.settleSubmitAt">
        <div class="header-title">{{ $t('pages_receive_175') }}</div>
        <div class="info-body">
          <div class="item">{{ $t('pages_receive_177') }}: <span>{{ receiveInfo.settleSubmitAt }}</span></div>
          <div class="item">{{ $t('pages_receive_178') }}: <span>{{ receiveInfo.merRate }}</span></div>
          <div class="item">{{ $t('pages_receive_174') }}: <span>{{ receiveInfo.buyCur }}</span></div>
          <div class="item">{{ $t('pages_receive_179') }}: <span>{{ receiveInfo.buyAmountText }}</span></div>
        </div>
      </div>
    </div>
    <template #footer>
      <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
    </template>
  </a-modal>
</template>
<script>
import { getCountryLogo } from '@/util'
export default {
  name: 'ReceiveDetail',
  props: {
    visible: {
      type: Boolean,
      default: () => { return true }
    },
    receiveInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup() {
    return {
      getCountryLogo
    }
  }
}
</script>

<style lang="less">
.receive-detail {
  .receive-info {
    margin: -5px 0;
    .header-title {
      padding: 12px 24px;
      background: @table-th-color;
      font-weight: 500;
      font-size: 16px;
    }
    .info-body {
      display: flex;
      flex-wrap: wrap;
      padding: 18px 0;
      .item {
        flex: 0 0 33.33%;
        margin-bottom: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 24px;
        img {
          width: 21px;
          height: 14px;
          margin: 0 5px 0 15px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
