<template>
  <a-modal
    wrap-class-name="settle-apply-modal"
    width="888px"
    :visible="visible"
    :title="receiveInfo.status === '23' ? $t('pages_receive_172') : $t('pages_receive_173')"
    :mask-closable="false"
    :confirm-loading="loading"
    :ok-text="$t('pages_receive_180')"
    @ok="handleOk"
    @cancel="$emit('update:visible', false)"
  >
    <a-alert v-if="receiveInfo.status === '26'" type="error" banner style="margin-bottom: 24px">
      <template #message>
        <span>{{ $t('pages_holder_010') }}: </span>
        <span>{{ rejectReason }}</span>
      </template>
    </a-alert>
    <a-form
      ref="exchangeRef"
      class="exchange-form flex-align ant-round-form"
      layout="vertical"
      :model="exchangeForm"
      :rules="exchangeRules"
    >
      <div class="sell-area">
        <a-form-item name="receiveAmount">
          <template #label>
            <span style="font-size: 15px; margin-left: 10px">{{ $t('pages_receive_006') }}</span>
          </template>
          <a-input
            v-model:value="exchangeForm.receiveAmount"
            size="large"
            disabled
            :placeholder="$t('common_text_006')"
          />
          <a-form-item-rest>
            <a-select
              v-model:value="exchangeForm.receiveCurrency"
              size="large"
              option-filter-prop="label"
              class="area-select"
              disabled
              :bordered="false"
            >
              <template #suffixIcon>
                <img src="~@/assets/icons/ic-choose-active.png" class="ic-select">
              </template>
              <a-select-option
                v-for="item in receiveCurList"
                :key="item.currency"
                :value="item.currency"
                :label="`${item.currency} - ${item.name}`"
              >
                <img :src="getCountryLogo(item.currency)" class="select-img-country">
                <span>{{ item.currency }}</span>
              </a-select-option>
            </a-select>
          </a-form-item-rest>
        </a-form-item>
      </div>
      <div class="split-area">
        <img src="~@/assets/icons/ic-exchange.png">
      </div>
      <div class="buy-area">
        <a-form-item name="settleAmount">
          <template #label>
            <span style="font-size: 15px; margin-left: 10px">{{ $t('pages_receive_174') }}</span>
          </template>
          <a-input
            v-model:value="exchangeForm.settleAmount"
            size="large"
            disabled
            :placeholder="$t('common_text_006')"
          />
          <a-form-item-rest>
            <a-select
              v-model:value="exchangeForm.settleCurrency"
              size="large"
              option-filter-prop="label"
              class="area-select"
              :bordered="false"
              @change="loadExchangeRate"
            >
              <template #suffixIcon>
                <img src="~@/assets/icons/ic-choose-active.png" class="ic-select">
              </template>
              <a-select-option
                v-for="item in settleCurList"
                :key="item.currency"
                :value="item.currency"
                :label="`${item.currency} - ${item.name}`"
              >
                <img :src="getCountryLogo(item.currency)" class="select-img-country">
                <span>{{ item.currency }}</span>
              </a-select-option>
            </a-select>
          </a-form-item-rest>
        </a-form-item>
      </div>
    </a-form>
    <div v-if="exchangeForm.receiveCurrency !== exchangeForm.settleCurrency" class="exchange-rate flex-align">
      <a-spin v-if="rateLoading" :tip="$t('pages_exchange_030')" />
      <div v-else-if="rateInfo.rate" class="rate-info flex-align">
        <img src="~@/assets/icons/ic-great.png">
        <div class="rate flex-align">
          <span>{{ $t('pages_exchange_028') }}</span>
          <span style="margin-left: 3px">{{ rateInfo.rate }}</span>
        </div>
        <div class="update-date">
          {{ $t('pages_exchange_029') }} {{ rateInfo.queryDate }}
        </div>
      </div>
      <div v-else class="rate-error">{{ rateInfo.resMsg }}</div>
      <div v-if="!rateLoading && exchangeForm.receiveCurrency && exchangeForm.settleCurrency && visible" class="refresh-process">
        <count-down
          :valid-time="rateInfo.validTime"
          :trade-currency="`${exchangeForm.receiveCurrency}${exchangeForm.settleCurrency}`"
          @refresh="loadExchangeRate"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import CountDown from '@/components/count-down'
import { reactive, toRefs, watch } from 'vue'
import { getCountryLogo, evalMathStr } from '@/util'
import { _receive, _exchange } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'SettleApply',

  components: {
    'count-down': CountDown
  },

  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    receiveInfo: {
      type: Object,
      default: () => { return {} }
    }
  },

  setup (props, ctx) {
    const state = reactive({
      loading: false,
      rateLoading: false,
      rejectReason: '',
      rateInfo: {
        rate: '',
        resMsg: '',
        validTime: null,
        arithmetic: '',
        queryDate: ''
      },
      receiveCurList: [],
      settleCurList: [],
      exchangeForm: {
        settleCurrency: '',
        receiveCurrency: '',
        settleAmount: null,
        receiveAmount: null
      }
    })

    watch(() => props.visible,
      value => {
        if (value) loadReceiveRateCur()
        if (props.receiveInfo.status === '26') loadRejectReason()
      })

    const handleOk = () => {
      state.loading = true
      const { settleCurrency, receiveCurrency, settleAmount, receiveAmount } = state.exchangeForm
      const data = {
        applyNo: props.receiveInfo.applyNo,
        sellCurrency: receiveCurrency,
        buyCurrency: settleCurrency,
        sellAmount: receiveAmount,
        buyAmount: settleAmount,
        rate: state.rateInfo.rate,
        lockRateType: '00',
        sellDirection: 'SELL'
      }
      _receive.tradeExchangeApply(data)
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_receive_051'))
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
          } else {
            console.log(res)
            message.error(res.data.resMsg)
          }
        })
        .catch(err => {
          state.loading = false
          console.log(err)
        })
    }

    const loadRejectReason = async () => {
      const res = await _receive.getRejectReason({ applyNo: props.receiveInfo.applyNo })
      if (res.data.succ) state.rejectReason = res.data.data
    }

    const loadReceiveRateCur = async () => {
      state.rateLoading = true
      const { entryCurrency, entryAmount } = props.receiveInfo
      state.exchangeForm.receiveCurrency = entryCurrency
      state.exchangeForm.receiveAmount = entryAmount
      state.receiveCurList = [{ currency: entryCurrency, name: null }]
      const res = await _receive.getReceiveRateCur({ applyNo: props.receiveInfo.applyNo })
      if (res.data.succ) {
        const { data } = res.data
        state.exchangeForm.settleCurrency = data[0].currency
        state.settleCurList = data
        loadExchangeRate()
      }
    }

    const loadExchangeRate = async () => {
      state.rateInfo = {
        rate: '',
        resMsg: '',
        validTime: 12,
        arithmetic: '',
        queryDate: ''
      }
      const { settleCurrency, receiveCurrency } = state.exchangeForm
      if (settleCurrency === receiveCurrency) {
        state.rateInfo.rate = 1
        state.rateInfo.arithmetic = 'DIV'
        calcSettleAmount()
        state.rateLoading = false
        return
      }
      state.rateLoading = true
      const res = await _exchange.getExchangeRate({ buyCurrency: settleCurrency, sellCurrency: receiveCurrency })
      if (res.data.succ) {
        const { data } = res.data
        state.rateInfo = Object.assign({}, data, {
          resMsg: '',
          validTime: data.validTime ? data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1) : 12
        })
        calcSettleAmount()
      } else {
        state.rateInfo.resMsg = res.data.resMsg
      }
      state.rateLoading = false
    }

    const calcSettleAmount = () => {
      const { receiveAmount, settleCurrency } = state.exchangeForm
      const { rate, arithmetic } = state.rateInfo
      if (!arithmetic) return
      let settleAmount = null
      if (arithmetic === 'DIV') {
        if (settleCurrency === 'JPY') {
          settleAmount = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1))
        } else {
          settleAmount = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1) * 100) / 100
        }
      } else {
        if (settleCurrency === 'JPY') {
          settleAmount = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1))
        } else {
          settleAmount = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1) * 100) / 100
        }
      }
      state.exchangeForm.settleAmount = settleAmount
    }

    return {
      getCountryLogo,
      handleOk,
      loadReceiveRateCur,
      loadExchangeRate,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.settle-apply-modal {
  .exchange-form {
    .sell-area, .buy-area {
      flex: 3;
      .area-select {
        left: auto;
        right: 1px;
        top: 1px;
        bottom: 1px;
        width: 30%;
        .ic-select {
          width: 8px;
          margin-top: -4px;
        }
      }
    }
    .sell-area:deep(.ant-input[disabled]) {
      background: #fff;
      color: #000;
    }
    .buy-area:deep(.ant-input[disabled]) {
      background: #fff;
      color: #000;
    }
    .split-area {
      margin-top: 5px;
      text-align: center;
      flex: 1;
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .exchange-rate {
    .rate-info {
      img {
        width: 25px;
        height: 25px;
        margin-top: -6px;
      }
      .rate {
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;
      }
      .update-date {
        font-size: 12px;
        color: #666;
        margin-left: 8px;
      }
    }
    .rate-error {
      color: @error-color;
    }
    .refresh-process {
      width: 20px;
      height: 20px;
      padding: 2px;
      overflow: hidden;
      margin-left: 5px;
    }
  }
}
</style>

